<template>
  <div class="classification-edit">
    <van-tabs v-model="tabs"
              @change="handleTabsClick">
      <van-tab title="合同详情">
        <van-form @submit="onSubmit">
          <van-field v-model="classificationInfo.name"
                     name="合同名称"
                     label="合同名称"
                     placeholder="请输入"
                     required
                     :rules="[{ required: true, message: '请输入' }]" />
          <van-field v-model="classificationInfo.serialNumber"
                     name="合同编号"
                     label="合同编号"
                     placeholder="请输入"
                     required
                     :rules="[{ required: true, message: '请输入' }]">
            <template #button>
              <van-button size="mini"
                          type="primary"
                          native-type="button"
                          :disabled="isClick||disable"
                          @click="postDocumentsSerialNumber">生成</van-button>
            </template>
          </van-field>
          <van-field v-model="classificationInfo.sponsorName"
                     name="甲方"
                     label="甲方"
                     placeholder="请输入"
                     required
                     :rules="[{ required: true, message: '请输入' }]" />
          <van-field name="乙方"
                     label="乙方"
                     placeholder="请选择"
                     required>
            <template #input>
              <div class="content">
                <!-- 
                   :class="{scroll:isDropdown,hidden:!isDropdown}" -->
                <div class="text-button"
                     @click="addParticipator">添加</div>
                <div class="content-line"
                     v-for="(item,index) in participatorList"
                     :key="index">
                  <div class="line-content"
                       :key="item.id">
                    <van-dropdown-menu>
                      <van-dropdown-item @open="dropdownOpenBtn"
                                         @close='dropdownCloseBtn'
                                         :ref="'dropdowRef'+index">
                        <template #title>
                          <van-field v-model="item.participatorName"
                                     placeholder="请输入" />
                        </template>
                        <template #default>
                          <van-cell v-for="(i,radioInd) in setOptions(item)"
                                    :key="radioInd"
                                    :title="i.value"
                                    @click="selectItenBtn(i,index,'dropdowRef'+index)">
                            <template #right-icon>
                              <van-icon v-if="i.isChoice"
                                        style="vertical-align: middle;line-height:2"
                                        name="success" />
                            </template>
                          </van-cell>
                        </template>
                      </van-dropdown-item>
                    </van-dropdown-menu>
                  </div>
                  <div class="delete"
                       @click="deldteParticipator(index)">
                    <van-icon name="delete-o" />
                  </div>
                </div>
              </div>
            </template>
          </van-field>
          <van-field v-model="classificationInfo.signedDate"
                     name="合同签订日期"
                     label="合同签订日期"
                     placeholder="请输入"
                     readonly
                     @click="showCalendarBtn('single','signedDate')" />
          <van-field name="合同起止日期"
                     label="合同起止日期"
                     placeholder="请输入"
                     readonly
                     @click="showCalendarBtn('range','date')">
            <template #input>
              <span v-if="classificationInfo.date.length>0"> {{classificationInfo.date[0]}}~{{classificationInfo.date[1]}}</span>
            </template>
          </van-field>
          <van-field v-model="classificationInfo.type"
                     name="合同类型"
                     label="合同类型"
                     placeholder="请选择"
                     required
                     @click="pickerBtn('type')"
                     readonly
                     :rules="[{ required: true, message: '请选择' }]" />
          <van-field v-model="classificationInfo.documentStatus"
                     name="合同状态"
                     label="合同状态"
                     placeholder="请选择"
                     required
                     readonly
                     @click="pickerBtn('documentStatus')"
                     :rules="[{ required: true, message: '请选择' }]" />

          <van-field v-model="classificationInfo.amount"
                     @input="
                    $event => {
                       classificationInfo.amount = $utils.limitInput($event);
                    }
                  "
                     name="金额"
                     label="金额"
                     placeholder="请输入" />
          <van-field name="合同文件"
                     label="合同文件">
            <template #input>
              <div class="content">
                <!-- v-model="fileList" -->
                <van-uploader accept=".doc,.docx,.pdf,.jpg,.png,.xls"
                              :after-read='addCommodity'
                              :disabled="isClick||disable"
                              :deletable="false">
                  <van-button icon="plus"
                              type="info"
                              size="small">上传附件</van-button>
                </van-uploader>
                <p>支持扩展名:.doc .docx .pdf .jpg...</p>
                <div class="content-line"
                     v-for="(item,index) in fileList"
                     :key="index">
                  <div class="name">
                    {{item.name}}
                  </div>

                  <div>
                    <van-icon name="delete-o"
                              class="line-deldete"
                              @click="uploadRemove(item,index)" />
                    <a :href="item.url"
                       :download="item.name">下载 </a>
                  </div>
                </div>
              </div>
            </template>
          </van-field>

          <van-field v-model="classificationInfo.remark"
                     name="备注"
                     label="备注"
                     placeholder="请输入" />

          <div style="margin: 16px;">
            <van-button round
                        block
                        type="info"
                        native-type="submit"
                        :disabled="isClick||disable">提交</van-button>
          </div>
        </van-form>
      </van-tab>
      <van-tab title="修改记录">
        <van-list v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  finished-text="没有更多了"
                  @load="onLoad">
          <van-cell>
            <template #default>
              <div v-for="(item,index) in list"
                   :key="index"
                   class="list-line">
                <span>{{item.editUserName}}</span>
                <span>{{item.creationTime}}</span>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-tab>
    </van-tabs>

    <!-- 选择日期 -->
    <van-calendar v-model="showCalendar"
                  :type="calendarType"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirm" />

    <!-- 选择弹框 -->
    <van-popup v-model="showPopup"
               round
               position="bottom">
      <custom-popup :title="pickerTitle"
                    :columns="pickerColumns"
                    @cancel="showPopup = false"
                    @confirm="pickerConfirm"></custom-popup>
    </van-popup>

  </div>
</template>

<script>
import Filter from "ldap-filters";
import { SUCCESS_CODE } from "@/apis/notification";
import { flatMap, find } from "lodash-es";
export default {
  name: "CommodityList",
  data() {
    return {
      tabs: "",

      classificationInfo: {
        name: "",
        documentCategoryId: "",
        participatorName: "",
        status: "EFFECTIVE",
        type: "",
        remark: "",
        signedDate: "",
        date: [],
        sponsorId: "",
        sponsorName: "广州市番禺区卫生技术人员继续教育中心",
        serialNumber: "",
        documentStatus: "",
        userId: "",
        userName: "",
        amount: null
      },
      list: [],
      participatorList: [],
      fileList: [],
      listStatus: {
        loading: true,
        finished: false
      },
      minDate: "",
      maxDate: "",
      calendarType: "",
      showCalendar: false,
      pager: {
        pageNumber: 1,
        pageSize: 10,
        totalRecords: 0
      },
      classificationType: [],
      documentStatusList: [],
      secondPartyList: [],
      showPopup: false,
      pickerColumns: [],
      participatorList: [
        {
          participatorName: "",
          participatorId: ""
        }
      ],
      pickerTitle: "",
      isClick: false,

      disable: false,
      isDropdown: true
    };
  },
  computed: {
    setOptions() {
      let isChoice = false;
      return item => {
        return this.secondPartyList
          .filter(row => {
            return row.value.includes(item.participatorName);
          })
          .map(row => {
            isChoice = row.value == item.participatorName;
            return {
              isChoice: isChoice,
              value: row.value
            };
          });
      };
    }
  },
  methods: {
    init() {
      this.minDate = new Date(this.$moment().add(-1, "y").format("YYYY-MM-DD"));
      this.maxDate = new Date(this.$moment().add(1, "y").format("YYYY-MM-DD"));
      this.fileListUrl = [];
      this.urlDelete = [];
      this.participatorDelete = [];
      this.isClick = false;

      if (this.$route.query && this.$route.query.power && this.$route.query.power == "user") {
        this.disable = true;
      }
      if (this.tabs == 0) {
        this.getDictsList();
        if (this.$route.query.id) this.getDocumentsEdit(this.$route.query.id);
      }
      if (this.tabs == 1) {
        this.listStatus = {
          loading: true,
          finished: false
        };
        this.pager = {
          pageNumber: 1,
          pageSize: 10,
          totalRecords: 0
        };
        this.getDocumentEditRecords();
      }
    },
    async onSubmit() {
      let isFlag = this.participatorList.some(val => {
        return !val.participatorName;
      });
      if (isFlag) return this.$utils.warning("请输入乙方");
      this.isClick = true;
      let params = {};
      let {
        name,
        documentCategoryId,
        status,
        type,
        remark,
        startDate,
        endDate,
        signedDate,
        sponsorId,
        sponsorName,
        amount,
        userId,
        userName,
        serialNumber,
        documentStatus
      } = this.classificationInfo;
      params = {
        name,
        documentCategoryId,
        status,
        type,
        remark,
        startDate,
        endDate,
        signedDate,
        serialNumber,
        documentStatus,
        // sponsorId,
        sponsorName,
        amount
        // userId,
        // userName
      };
      if (this.classificationInfo.date.length) {
        params.startDate = this.classificationInfo.date[0];
        params.endDate = this.classificationInfo.date[1];
      }
      params.documentCategoryId = this.$route.query.documentCategoryId;
      if (this.classificationInfo.id) {
        this.putDocumentsId(params, this.classificationInfo.id);
        await this.participatorList.map(val => {
          this.$api.deleDocumentsDocumentIdParticipators({
            placeholder: {
              documentId: this.classificationInfo.id
            }
          });
        });
        this.postDocumentsDocumentIdParticipators(this.classificationInfo.id);

        if (this.urlDelete.length) {
          let ids = this.urlDelete.map(val => {
            return val.id;
          });
          this.$api.deleDocumentsDocumentIdAttachments({
            placeholder: {
              documentId: this.classificationInfo.id,
              ids: ids.join(",")
            }
          });
        }
        this.postDocumentsDocumentIdAttachments(this.classificationInfo.id);
      } else {
        let ret = await this.postDocuments(params);
        let documentId = ret.data.id; //: "d0ce4569-8f69-4cd1-88a2-9334a5db2425"
        this.postDocumentsDocumentIdParticipators(documentId);
        if (this.fileListUrl.length) {
          this.postDocumentsDocumentIdAttachments(documentId);
        }
      }
      this.$utils.success("操作成功");
      setTimeout(async () => {
        await this.$router.go(-1);

        setTimeout(() => {
          window.scrollTo(0, 0);
          this.$parent.$refs[window.currentRoute].init();
        }, 500);
      }, 750);
    },
    postDocumentsDocumentIdAttachments(documentId) {
      this.$api.postDocumentsDocumentIdAttachments({
        params: this.fileListUrl.filter(val => {
          return !val.id;
        }),
        placeholder: {
          documentId
        }
      });
    },
    postDocumentsDocumentIdParticipators(documentId) {
      this.$api.postDocumentsDocumentIdParticipators({
        params: this.participatorList.map(val => {
          return {
            participatorName: val.participatorName,
            participatorId: ""
          };
        }),
        placeholder: {
          documentId: documentId
        }
      });
    },
    uploadRemove(row, index) {
      this.fileList = this.fileList.filter((val, ind) => {
        return index != ind;
      });
      if (row.url) {
        this.urlDelete.push({ url: row.url, id: row.id });
      }
    },
    onLoad() {
      this.getDocumentEditRecords(true);
    },
    onConfirm(date) {
      this.showCalendar = false;
      if (this.timeKey == "signedDate") {
        this.classificationInfo[this.timeKey] = this.$moment(date).format("YYYY-MM-DD");
      }
      if (this.timeKey == "date") {
        this.classificationInfo[this.timeKey] = [this.$moment(date[0]).format("YYYY-MM-DD"), this.$moment(date[1]).format("YYYY-MM-DD")];
      }
    },
    showCalendarBtn(type, key, isClick = false) {
      if (isClick || this.isClick) return false;
      this.calendarType = type;
      this.timeKey = key;
      this.showCalendar = true;
    },
    // 编辑
    async putDocumentsId(params, id) {
      return await this.$api.putDocumentsId({ params, placeholder: { id } });
    },
    async getDocumentsEdit(pid) {
      let ret = await this.$api.getDocumentsId({ placeholder: { id: pid } });
      let {
        name,
        documentCategoryId,
        status,
        type,
        remark,
        startDate,
        endDate,
        signedDate,
        sponsorId,
        sponsorName,
        amount,
        serialNumber,
        documentStatus,
        userId,
        userName,
        id
      } = ret.data;
      this.classificationInfo = {
        id,
        name,
        documentCategoryId,
        status,
        type,
        remark,
        sponsorId,
        sponsorName,
        amount,
        serialNumber,
        documentStatus,
        userId,
        userName,
        signedDate,
        date: [startDate, endDate]
      };
      this.participatorList = ret.data.participators;
      // this.fileList = ret.data.attachments.map((val, ind) => {
      //   return {
      //     name: ind + "." + val.suffix,
      //     url: val.attachmentUrl,
      //     id: val.id
      //   };
      // });
      this.fileList = await Promise.all(
        ret.data.attachments.map(async (val, ind) => {
          let regex = /\[(.+?)\]/g; // 中括号
          let name;
          try {
            name = val.attachmentUrl.match(regex)[0].replace("[", "").replace("]", "");
          } catch (error) {
            name = ind + "." + val.suffix;
          }
          return {
            name,
            url: await this.getAppendixes(val),
            id: val.id
          };
        })
      );
      // getAppendixes
    },
    addParticipator() {
      // !this.participatorList[this.participatorList.length - 1].participatorName
      let isFlag = this.participatorList.some(val => {
        return !val.participatorName;
      });
      if (isFlag) {
        this.$utils.warning("请输入乙方");
      } else {
        this.participatorList.push({
          participatorName: "",
          participatorId: ""
        });
      }
    },
    async getDocumentEditRecords(sign = false) {
      let params = { pageNumber: this.pager.pageNumber - 1, pageSize: this.pager.pageSize, query: "(documentId=*" + this.$route.query.id + "*)" };
      let ret = await this.$api.getDocumentEditRecords({ params });
      // this.list = ret.data;
      this.listStatus.loading = false;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    // 保存合同
    async postDocuments(params) {
      return await this.$api.postDocuments({ params });
    },
    // 获取字典
    async getDictsList() {
      let ret = await this.$api.getDictsList({
        params: {
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.classificationType = ret.data["合同类型"][0].dictOptions;
      this.documentStatusList = ret.data["合同状态"][0].dictOptions;
      this.secondPartyList = ret.data["合同乙方"][0].dictOptions;
    },
    pickerConfirm(val) {
      this.classificationInfo[val.key] = val.text;
      this.showPopup = false;
    },
    deldteParticipator(index) {
      if (this.participatorList.length < 2) return false;
      this.participatorList = this.participatorList.filter((val, ind) => {
        if (index == ind) {
          this.participatorDelete.push(val);
        }
        return index != ind;
      });
    },
    pickerBtn(type, isClick = false) {
      if (isClick) return;
      this.showPopup = true;
      // classificationType
      if (type == "type") {
        this.pickerTitle = "合同类型";
        this.pickerColumns = this.classificationType.map(val => {
          return { text: val.value, key: "type", id: val.id };
        });
      }
      if (type == "documentStatus") {
        this.pickerTitle = "合同状态";
        this.pickerColumns = this.documentStatusList.map(val => {
          return { text: val.value, key: "documentStatus", id: val.id };
        });
      }
    },
    async addCommodity(val) {
      let { file } = val;
      let suffix = file.name.slice(file.name.lastIndexOf(".") + 1);
      let type = ["jpg", "jpeg", "png", "jpe "].includes(suffix) ? "IMAGE" : "FILE";
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "DOCUMENT");
      let ret = await this.postAppendixFilePath(formData);
      this.fileList.push({ name: file.name });
      this.fileListUrl.push({
        attachmentUrl: ret.data,
        status: "ENABLE",
        type,
        suffix
      });
    },
    // 图片上传
    postAppendixFilePath(files) {
      let ret = this.$api.postAppendixFilePath({ params: files });
      return ret;
    },
    async getAppendixes(par) {
      let ret = await this.$api.getAppendixFilePath({ params: { urls: par.attachmentUrl } });
      return ret.data[0];
    },
    handleTabsClick() {
      this.init();
    },
    // 获取合同编号
    async postDocumentsSerialNumber() {
      this.$utils.debounce(async () => {
        let ret = await this.$api.postDocumentsSerialNumber();
        this.$set(this.classificationInfo, "serialNumber", ret.data);
      }, 1000);
    },
    dropdownOpenBtn(row) {
      this.isDropdown = false;
    },
    dropdownCloseBtn() {
      this.isDropdown = true;
    },
    selectItenBtn(item, ind, ref) {
      this.participatorList[ind].participatorName = item.value;
      // this.$refs[ref].toggle(false);
      console.log(123, this.$refs[ref][0].toggle(false));
      this.dropdownCloseBtn();
    }
  },
  created() {
    this.init();
  }
};
</script>
<style lang="less">
.classification-edit {
  .content {
    // height: 200px;
    .text-button {
      color: @up-primary;
    }
    .content-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 5px 0;
      .line-deldete {
        padding-right: 5px;
      }
      .name {
        width: 80px;
      }

      .value {
        flex: 1;
      }
      .line-content {
        padding: 5px;
        .van-dropdown-item__content {
          max-height: 180px;
        }
      }
      .delete {
        font-size: 30px;
      }
      .van-dropdown-menu__title::after {
        border: none;
      }
    }
  }

  .scroll {
    overflow: auto;
  }
  .hidden {
    overflow: hidden;
  }
  .list-line {
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>

